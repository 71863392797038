import SecureView from "../../../shared/common/SecureView";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import findCompaniesRequest from "../../../core/api/requests/companies/findCompaniesRequest";
import getCompanyStockSummary from "../../../core/api/requests/company/company-stock/getCompanyStockSummary";
import IStockProduct from "../../../core/interfaces/IStockProduct";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import Paginator from "../../../shared/common/Paginator";
import bindModuleCommonSettings from "../../../core/utils/bindModuleCommonSettings";
import loadModuleInformation from "../../../core/utils/loadModuleInformation";
import ICompanyModule from "../../../core/interfaces/ICompanyModule";
import CapitalizeFirstLetter from "../../../core/utils/CapitalizeFirstLetter";
import findCompanySubdepartmentsRequest
  from "../../../core/api/requests/company/company-departments/subdepartments/findCompanySubdepartmentsRequest";
import ISubdepartment from "../../../core/interfaces/ISubdepartment";
import IDepartment from "../../../core/interfaces/IDepartment";
import IProduct from "../../../core/interfaces/IProduct";
import {useNavigate, useParams} from "react-router-dom";
import CapitalizeAndTransform from "../../../core/utils/CapitalizeAndTransform";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import {setDepartments, setProducts} from "../../../core/store/slices/companyCommons";
import findCompanyProductsRequest from "../../../core/api/requests/company/company-products/findCompanyProductsRequest";
import getMyCompanyProfileRequest from "../../../core/api/requests/me/getMyCompanyProfileRequest";
import IProductUnit from "../../../core/interfaces/IProductUnit";
import CompanyProductStockSeeModal from "../../../shared/modals/CompanyProductStockSeeModal";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  method: ECommonPermissions.CREATE,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)
  const companyCommons = useSelector((state: RootState) => state.companyCommons)

  const [ products, setProductsList ] = useState<IStockProduct[]>([])
  const params = useParams()

  const [ departmentModule, setDepartmentModule ] = useState<ICompanyModule>()
  const [ subdepartments, setSubdepartments ] = useState<ISubdepartment[]>([])

  const [ status, setStatus ] = useState<string>('')
  const [ subdepartment, setSubdepartment ] = useState<ISubdepartment>()
  const [ department, setDepartment ] = useState<IDepartment>()
  const [ product, setProduct ] = useState<IProduct>()
  const navigate = useNavigate();

  const [ showProductStockModal, setShowProductStockModal ] = useState<string>('')

  const loadModules = () => {
    const department = loadModuleInformation(selectedCompany?.company, EModules.COMPANY_DEPARTMENTS)
    if (department) {
      setDepartmentModule(department)
    }
  }

  const find = async (pageByParam?: number) => {
    let company: any = selectedCompany.company
    if (!company && params.cid) {
      company = await getMyCompanyProfileRequest(dispatch, params.cid);
    }

    if (!company) {
      return;
    }

    const response = await getCompanyStockSummary(
      dispatch,
      {
        departmentId: department?.id,
        subDepartmentId: subdepartment?.id,
        productId: product?.id,
      },
      company,
    )

    if (response?.data) {
      setProductsList(response.data)
    }
  }

  useEffect(() => {
    find()
  }, [department, subdepartment, product, status]);

  useEffect(() => {
    loadModules()
  }, [selectedCompany.company])

  useEffect(() => {
    find()
    loadModules()
    getDepartments()
    getProducts()
  }, [])

  const getDepartments = async () => {
    let company: any = selectedCompany.company
    if (!company && params.cid) {
      company = await getMyCompanyProfileRequest(dispatch, params.cid);
    }

    if (!company) {
      return;
    }

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        page: 1,
        perPage: 100,
        query: '',
      },
      company,
    )

    if (response?.data) {
      dispatch(setDepartments(response.data.data))
    }
  }

  const getProducts = async () => {
    let company: any = selectedCompany.company
    if (!company && params.cid) {
      company = await getMyCompanyProfileRequest(dispatch, params.cid);
    }

    if (!company) {
      return;
    }

    const response = await findCompanyProductsRequest(
      dispatch,
      {
        page: 1,
        perPage: 100,
        query: '',
      },
      company,
    )

    if (response?.data) {
      dispatch(setProducts(response.data.data))
    }
  }

  const getSubdepartments = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findCompanySubdepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      setSubdepartments(response.data.data)
    }
  }


  const selectDepartment = (id: string) => {
    const d = companyCommons.departments.find((dt) => dt.id === id)
    if (!d) {
      setDepartment(undefined)
      return
    }

    getSubdepartments(d.id || '')

    setDepartment(d)
  }

  const selectSubdepartment = (id: string) => {
    const d = subdepartments.find((dt) => dt.id === id)
    if (!d) {
      setSubdepartment(undefined)
      return
    }

    setSubdepartment(d)
  }

  const selectProduct = (id: string) => {
    const d = companyCommons.products.find((dt) => dt.id === id)
    if (!d) {
      setProduct(undefined)
      return
    }

    setProduct(d)
  }

  const seeProductsByFilter = (pu: IStockProduct) => {
    if (!pu.product?.id) return
    setShowProductStockModal(pu.product.id)
  }

  return <SecureView module={module} setModule={setModule}>
    <CompanyProductStockSeeModal
      onClose={() => setShowProductStockModal('')}
      show={!!showProductStockModal}
      productId={showProductStockModal}
      module={module}
    />

    <Card className={'col-12'}>
      <CardBody>
        <Row>
          <Col sm={3}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.moduleName || '')}</label>
            <select className={'custom-select'} value={department?.id}
                    onChange={(e) => selectDepartment(e.target.value)}
            >
              <option>Selecciona...</option>
              {companyCommons.departments.map((depto) => {
                return <option key={depto.id} value={depto.id}>{depto.name}</option>
              })
              }
            </select>
          </Col>
          <Col sm={3}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.subdepartmentName || '')}</label>
            <select disabled={!department} className={'custom-select'} value={subdepartment?.id}
                    onChange={(e) => selectSubdepartment(e.target.value)}>
              <option>Selecciona...</option>
              {subdepartments.map((st) => {
                return <option key={st.id} value={st.id}>{st.name}</option>
              })
              }
            </select>
          </Col>
          <Col sm={3}>
            <label>Producto</label>
            <select className={'custom-select'} value={product?.id}
                    onChange={(e) => selectProduct(e.target.value)}>
              <option>Selecciona...</option>
              {companyCommons.products.map((pt) => {
                return <option key={pt.id}
                               value={pt.id}>[{pt.sku}] {pt.name} ({pt.presentationValue} {pt.presentationMeasurementUnit})</option>
              })
              }
            </select>
          </Col>
          <Col sm={3}>
            <label>Estado</label>
            <select className={'custom-select'} value={''}
                    onChange={(e) => console.log('departmentId', e.target.value)}>
              <option>Selecciona...</option>
            </select>
          </Col>
        </Row>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {products.map((product) => {
              return <tr key={product.product.id}>
                <td>{product.product.id}</td>
                <td>{product.product.sku}</td>
                <td>{product.product.name}</td>
                <td>{(product?.total || 0).toFixed(2)} {product.measurementUnit}</td>
                <td>
                  <Button size={'sm'} variant={'secondary'} onClick={() => seeProductsByFilter(product)}>
                    <i className={'ti-eye'}></i> Ver
                  </Button>
                </td>
              </tr>
            })
            }
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
