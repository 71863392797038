import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import QRCode from "react-qr-code";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import {useEffect, useState} from "react";
import '../../shared/styles/printer.css';

export default function () {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const scanner = useSelector((state: RootState) => state.scanner)
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  if (!scanner) {
    navigator(ERoutes.HOME)
    return <div></div>
  }

  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 500)

    window.onafterprint = () => {
      if (!searchParams.get('nc')) {
        window.close()
      }
    }
  }, []);

  return <div
    className={'printer'}
    style={{
      paddingTop: searchParams.get('pv') || '5mm',
      paddingBottom: searchParams.get('pv') || '5mm',
      paddingLeft: searchParams.get('ph') || '5mm',
      paddingRight: searchParams.get('ph') || '5mm',
      width: searchParams.get('w') || '100mm',
      height: searchParams.get('h') || '100mm',
    }}
  >
    {searchParams.get('c') &&
      <QRCode
        value={searchParams.get('c') || ''}
        style={{
          width: searchParams.get('qrw') || '20mm',
          height: searchParams.get('qrw') || '20mm',
        }}
      />
    }

    <div className={'mt-2 text-center'}>
      {searchParams.get('t') &&
        <p>{searchParams.get('t')}</p>
      }

      {searchParams.get('st') &&
        <p>{searchParams.get('st')}</p>
      }

      {searchParams.get('d') &&
        <p>{searchParams.get('d')}</p>
      }

      {searchParams.get('tt') &&
        <small>{searchParams.get('tt')}</small>
      }
    </div>
  </div>
}
